body {
  /* Apply different styles to the whole body */
  background-color: #f0f0f0;
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Add the provided styles for leaflet-container .leaflet-control-attribution */
.leaflet-container .leaflet-control-attribution {
  display: none;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
}
